<!-- 成绩查询 -->
<template>
    <div class="ScoreDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">成绩详情</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="flex: 1;">
            <div class="info">
                报名信息
            </div>
            <el-form ref="form" :model="form" class="form" inline label-width="150px">
                <el-form-item label="状态">
                    <el-input :value="form.condition" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="登记单号">
                    <el-input :value="form.number" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="机构名称">
                    <el-input :value="form.fullName" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="考生姓名">
                    <el-input :value="form.name" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="指导老师">
                    <el-input :value="form.teacher" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input :value="form.telephone" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="科目">
                    <el-input :value="form.subject" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="原等级">
                    <el-input :value="form.primaryRank" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="报考等级">
                    <el-input :value="form.signUp" placeholder="" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <div class="scoreinfo">
                成绩信息
            </div>
            <el-form ref="form" :model="form" class="form" inline label-width="150px">
                <el-form-item label="结论">
                    <el-input :value="form.conclusion" placeholder="" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="结论级别">
                    <el-input :value="form.level" placeholder="" disabled></el-input>
                </el-form-item> -->
            </el-form>
        </div>
        <el-divider></el-divider>
        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    condition:"",//状态
                    number:"",//登记单号
                    fullName:"",//机构名称
                    name:"",//考生姓名
                    teacher:"",//指导老师
                    telephone:"",//联系电话
                    subject:"",//科目
                    primaryRank:"",//原等级
                    signUp:"",//报考等级
                    conclusion:"",//结论
                    // level:""//结论级别
                },
                info:{}
            }
        },
       async created() {
          await this.$request({url:'/api/studentexam/scoreDetail', method: 'POST', data:{
               uid: localStorage.getItem('token'),
               signup_id:this.$route.query.id
           }}).then(res=>{
               console.log(res.data)
               let data = res.data 
               this.form.condition=data.status=="4"?'考试OK':''
               this.form.number=data.enroll_number
               this.form.fullName=data.org_name
               this.form.name=data.name
               this.form.teacher=data.teacher
               this.form.telephone=data.phone
               this.form.subject=data.course
               this.form.primaryRank=data.original_level
               this.form.signUp=data.exam_level
               this.form.conclusion=data.exam_result == '0' ? '-' : (data.exam_result =='1' ? '未录入' : (data.exam_result =='2' ? '通过' : '未通过'))
           })
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped="scoped">
    .ScoreDetails {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
 /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 30px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    .score {
        padding: 25px;
        font-weight: bold;
        font-size: 16px;
    }

    .info {
        font-size: 20px;
        font-weight: bold;
        width: 240px;
        text-align: right;
    }

    /* 报名信息部分 */
    .el-form {
        margin: 0px auto;
        padding: 30px 0px;
        width: 80%;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }


    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    .el-input {
        width: 430px;
        height: 37px !important;
    }
    .form ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
        color: #333333;
        border-color:#ccc;
        cursor: default;
    }
    /* 成绩信息 */
    .scoreinfo {
        font-size: 20px;
        font-weight: bold;
        width: 240px;
        padding-top: 30px;
        text-align: right;
    }
    /* 底部 */
    .buttom{
        height: 30px;
        line-height: 30px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
